import request from '../common/request';

export function getRoutes(data) {
  return request({
    url: '/api/blade-system/frontendmenu/routes',
    method: 'get',
    data
  })
}

export function getButtons(data) {
  return request({
    url: '/api/blade-system/frontendmenu/buttons',
    method: 'get',
    data
  })
}

// 获取字典
export const getDictionary = (code) => request({
  url: '/api/blade-system/dict-biz/dictionary',
  method: 'get',
  params:{
    code
  }
});



