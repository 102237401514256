const jWeixin = require("jweixin-module");

export default {
  initWeixinConfig: function (wechatConfig) {
    jWeixin.config({
      debug: process.env.NODE_ENV === "development", // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
      appId: wechatConfig.appId,// 必填，公众号的唯一标识
      timestamp: wechatConfig.timestamp,// 必填，生成签名的时间戳
      nonceStr: wechatConfig.nonceStr,// 必填，生成签名的随机串
      signature: wechatConfig.signature,// 必填，签名
      jsApiList: ["scanQRCode"],// 必填，需要使用的JS接口列表
    });

    jWeixin.error(function (err) {
      console.error(err.toString())
    });
  },
  scanQRCode: function (callback) {
    jWeixin.ready(function () {
      jWeixin.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (success) {
          callback && callback(success)
        },
      });
    })
  }
}