import request from '../common/request';

// 获取微信公众号配置
export function addUserScanLog() {
  return request({
    url: '/api/wifi-info/wifiscanlog/addUserScanLog',
    method: 'post',
  })
}



