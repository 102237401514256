<template>
  <div class="home">
    <div class="banner"></div>
    <div class="wrap">
      <h2 class="title">办理功能</h2>
      <ul class="list">
        <li
          class="item"
          v-for="(item, index) in list1"
          :key="index"
          @click="handleScanClick(item)"
        >
          <van-image :src="item.icon"></van-image>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="wrap1" v-if="userRoutes.length">
      <ul class="list">
        <li
          class="item"
          v-for="item in userRoutes"
          :key="item.id"
          :style="{ background: item.remark }"
          @click="userClick(item)"
        >
          <span>{{ item.name }}</span>
          <van-image :src="item.logo"></van-image>
        </li>
      </ul>
    </div>
    <div class="wrap2" v-if="adminRoutes.length">
      <h2 class="title">管理员</h2>
      <ul class="list">
        <li
          class="item"
          v-for="item in adminRoutes"
          :key="item.id"
          @click="adminClick(item)"
        >
          <van-image :src="item.logo"></van-image>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <!-- wifi弹框 -->
    <van-dialog v-model="wifiDialogShow" title="青原区政务服务中心wifi" @confirm="onWifiDialogConfirm" @open="onWifiDialogOpen">
      <p>wifi名称：{{wifiName}}</p>
      <p>wifi密码：{{wifiPw}}</p>
    </van-dialog>
  </div>
</template>

<script>
import { Image,Toast,Dialog } from "vant";
import { getWechatConfig } from "@/api/wechat";
import { getButtons } from "@/api/system";
import { lockScreen } from "@/api/lockscreen";
import { getApplyConfigList } from "@/api/apply";
import { addUserScanLog } from "@/api/wifi";
import wxConfig from "@/common/wxConfig";
import { mapState } from "vuex";
export default {
  name: "home",
  components: {
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      list1: [
        {
          name: "网办电脑解锁",
          icon: require("images/icon1.png"),
          type: "lock",
        },
        {
          name: "便民设备使用",
          icon: require("images/icon2.png"),
          type: "device",
        },
        {
          name: "大厅wifi连接",
          icon: require("images/icon3.png"),
          type: "wifi",
        },
      ],
      list2: [
        {
          name: "预约办理",
          bgColor: "linear-gradient(90deg, #51D48F 0%, #57DA96 100%)",
          icon: "",
          path: "/appoint",
        },
        {
          name: "我的预约",
          bgColor: "linear-gradient(90deg, #51D48F 0%, #57DA96 100%)",
          icon: "",
          path: "/my-appoint",
        },
        {
          name: "出件查询",
          bgColor: "linear-gradient(92deg, #418AFD 0%, #499BFE 100%)",
          icon: "",
          path: "/query",
        },
        {
          name: "大厅管理",
          bgColor: "linear-gradient(90deg, #F8A432 0%, #F4BD3A 100%)",
          icon: "",
          path: "/assets",
        },
        {
          name: "考勤打卡",
          bgColor: "linear-gradient(90deg, #F8A432 0%, #F4BD3A 100%)",
          icon: "",
          url: "./attendance/index.html",
        },
        {
          name: "消息通知 ",
          bgColor: "linear-gradient(90deg, #F8A432 0%, #F4BD3A 100%)",
          icon: "",
          path: "/notify",
        },
      ],
      userRoutes: [],
      adminRoutes: [],
      wifiDialogShow:false,
      wifiName:'',
      wifiPw:''
    };
  },
  mounted() {
    this.initWxConfig();
    this.getButtons();
  },
  methods: {
    async getButtons() {
      const res = await getButtons();
      const userRoute = res.data.find((item) => item.code === "user")?.children;
      const userApply = await getApplyConfigList()
      // console.log(userApply)
      this.userRoutes = userRoute.concat(userApply.data.map(item=>({
        id:item.id,
        remark:item.bgColor,
        name: item.applyName,
        logo: item.lconUrl,
        link: item.linkUrl
      })))
      this.adminRoutes = res.data.find(
        (item) => item.code === "admin"
      )?.children;
    },
    async initWxConfig() {
      const url = window.location.href.split("#")[0];
      const res = await getWechatConfig(encodeURIComponent(url));
      // console.log(res);
      wxConfig.initWeixinConfig(res.data);
    },
    handleScanClick({ type }) {
      // console.log(type);
      wxConfig.scanQRCode(async (res) => {
        console.log(res);
        const result = res.resultStr
        if(result === 'scan resultStr is here'){
          return false
        }
        if (type === "lock") {
          const res = await lockScreen({
            number: result,
            phone: this.userInfo.account,
          })
          // console.log(res)
          Toast(res.msg)
        }
        if (type === "device") {
          this.$router.push({
            path: "/apply",
            query: {
              id: result,
              type: "scan",
            },
          });
        }
        if (type === "wifi") {
          const [ wifiName, wifiPw ] = result.split(',')
          this.wifiName = wifiName
          this.wifiPw = wifiPw
          this.wifiDialogShow = true
        }
      });
    },
    async onWifiDialogOpen(){
      const res = await addUserScanLog()
      console.log(res)
    },
    onWifiDialogConfirm(){
      this.wifiDialogShow = false
    },
    userClick(item){
      if (item.path) {
        this.$router.push(item.path);
      } else {
        window.location.href = item.link
      }
    },
    adminClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else {
        window.location.href = `${item.alias}?refresh_token=${this.userInfo.refresh_token}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 355px;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 14px;
  margin-top: 10px;
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #1b2845;
  }
  .list {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-image {
        width: 50px;
        height: 50px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #0c0f18;
        margin-top: 10px;
      }
    }
  }
}
.wrap1 {
  width: 355px;
  background: #ffffff;
  border-radius: 5px;
  padding: 4px 14px 16px;
  margin-top: 10px;
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 158px;
      height: 65px;
      border-radius: 4px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 12px;

      span {
        font-size: 16px;
        color: #ffffff;
      }
      .van-image {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.wrap2 {
  width: 355px;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 14px;
  margin-top: 10px;
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #1b2845;
  }
  .list {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-image {
        width: 50px;
        height: 50px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #0c0f18;
        margin-top: 10px;
      }
    }
  }
}
.home {
  width: 100%;
  background: #f5f7fa;
  padding: 10px;
  .banner {
    width: 355px;
    height: 170px;
    background: url("~images/banner.png") left center no-repeat;
    background-size: 355px 170px;
  }
}
</style>